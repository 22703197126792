import React from "react"

import { Grommet, Box, TextInput, Meter, Card, Heading, Nav, Text, grommet } from 'grommet'
import { Search, SettingsOption, Achievement, Book, Tasks, User } from 'grommet-icons'


const theme = {
  "name": "dark",
  "rounding": 4,
  "spacing": 24,
  "defaultMode": "light",
  "global": {
    "colors": {
      "brand": {
        "dark": '#FD6FFF',
        "light": '#FD6FFF'
      },
      "background": {
        "dark": "#111111",
        "light": "#FFFFFF"
      },
      "background-back": {
        "dark": "#111111",
        "light": "#EEEEEE"
      },
      "background-front": {
        "dark": "#222222",
        "light": "#FFFFFF"
      },
      "background-contrast": {
        "dark": "#FFFFFF11",
        "light": "#11111111"
      },
      "text": {
        "dark": "#EEEEEE",
        "light": "#444444"
      },
      "text-strong": {
        "dark": "#FFFFFF",
        "light": "#000000"
      },
      "text-weak": {
        "dark": "#CCCCCC",
        "light": "#444444"
      },
      "text-xweak": {
        "dark": "#999999",
        "light": "#666666"
      },
      "border": {
        "dark": "#444444",
        "light": "#CCCCCC"
      },
      "control": {
        "light": "#403216",
        "dark": "#FFCA58"
      },
      "active-background": "background-contrast",
      "active-text": "text-strong",
      "selected-background": "brand",
      "selected-text": "text-strong",
      "status-critical": "#FF3333",
      "status-warning": "#F7E464",
      "status-ok": "#7DD892",
      "status-unknown": "#a8a8a8",
      "status-disabled": "#a8a8a8",
      "graph-0": "brand",
      "graph-1": "green",
      "red": {
        "dark": "#EB6060",
        "light": "#FD6FFF"
      },
      "red!": "",
      "green": {
        "dark": "#01C781",
        "light": "#60EB9F"
      },
      "green!": "",
      "blue": {
        "dark": "#FD6FFF",
        "light": "#FD6FFF"
      },
      "yellow": {
        "dark": "#FFB200",
        "light": "#FFCA58"
      },
      "yellow!": "",
      "graph-2": "yellow",
      "graph-3": "blue"
    },
    "font": {
      "family": "Arial"
    },
    "active": {
      "background": "active-background",
      "color": "active-text"
    },
    "hover": {
      "background": "active-background",
      "color": "active-text"
    },
    "selected": {
      "background": "selected-background",
      "color": "selected-text"
    }
  },
  "chart": {},
  "diagram": {
    "line": {}
  },
  "meter": {},
  "layer": {
    "background": {
      "dark": "#111111",
      "light": "#FFFFFF"
    }
  },
}

export default function NavWrapper() {

  return (
    <Grommet full theme={theme} themeMode='light'>
      <Box align="start" justify="end" direction="column" focusIndicator={false} fill>
        <Box align="center" justify="start" direction="row" fill="horizontal" pad={{ "horizontal": "medium", "vertical": "xsmall" }} border={{ "side": "bottom", "color": "active-background" }}>
          <Box align="center" justify="center" fill="horizontal"  margin={{ "right": "medium" }}>
            <TextInput icon={<Search />} plain type="text" size="medium" placeholder="Search" reverse textAlign="start" focusIndicator={false}/>
          </Box>
          <Nav align="center" justify="center" onClick={() => { }} direction="row">
            <SettingsOption size="medium"/>
          </Nav>
        </Box>
        <Meter value={50} max={100} color="brand" size="full" thickness="xsmall" round={false} />
        <Box align="center" justify="start" fill overflow="scroll" pad="medium">
          <Card pad="xsmall" fill margin="medium" background={{ "color": "active-background", "position": "center" }} border={{ "style": "hidden" }} onClick={() => { }} justify="center" align="stretch" direction="column">
            <Heading color="text" textAlign="center">
              日本語
            </Heading>
            <Heading color="text-weak" textAlign="center" size="small" level="2">
              Japanese
            </Heading>
          </Card>
        </Box>
        <Box align="center" justify="center" fill="horizontal" pad={{ "horizontal": "large", "top": "xsmall" }} background={{ "color": "background-contrast", "dark": false }} elevation="medium" direction="column">
          <Nav align="center" flex={false} justify="between" direction="row" fill="horizontal" margin={{ "bottom": "xlarge", "top": "medium" }}>
            <Box align="center" justify="center">
              <Achievement size="medium" color="text" />
              
            </Box>
            <Box align="center" justify="center">
              <Book size="medium"/>
            </Box>
            <Box align="center" justify="center">
              <Tasks size="medium" color="text" />
           
            </Box>
            <Box align="center" justify="center">
              <User size="medium" color="text" />
            
            </Box>
          </Nav>
        </Box>
      </Box>
    </Grommet>
  )
}
