import React, { Component } from 'react';
import {
  Box,
  Button,
  Collapsible,
  Card,
  Heading,
  Grommet,
  Layer,
  ResponsiveContext,
} from 'grommet';
import { FormClose, Notification, Menu } from 'grommet-icons';
import NavWrapper from './NavWrapper'

const theme = {
  global: {
    colors: {
      brand: '#228BE6',
      white: '#000000'
    },
    font: {
      family: 'Helvetica',
      size: '14px',
      height: '20px',
    },
  },
};

var data_schmea = {
  'class': {
    'pages': {
      'introduction': {
        'md': '#Hello Class!'
      },
      'lesson-1': {
        'md': '#Spanish Basics!'
      }
    },
    'language': 'Japanese',
    'units': {
      'unit_0': {
        'name': 'Getting Started'
      }
    }
  }
}

var user_schema = {

}


const AppBar = (props) => (
  <Box
    tag='header'
    direction='row'
    align='center'
    justify='between'
    background='brand'
    pad={{ left: 'medium', right: 'small', vertical: 'small' }}
    elevation='medium'
    style={{ zIndex: '1' }}
    {...props}
  />
);

class App extends Component {
  state = {
    showSidebar: false,
  }
  render() {
    const { showSidebar } = this.state;
    return (
      <NavWrapper/>
    )
  }
}

export default App;

